<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="groupProfile"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
        <md-table-empty-state md-label="No data to show"
          :md-description="`Try to select a region or another search criteria.`" />

            <md-table-row
              slot="md-table-row"
              slot-scope="{item}"

            >
              <md-table-cell
                :md-label="$t('stdCols.name_en')"
                md-sort-by="name_en"
              >{{ item.name_en }}</md-table-cell>
              <md-table-cell
                :md-label="$t('stdCols.name_ru')"
                md-sort-by="name_ru"
              >{{ item.name_ru }}</md-table-cell>
              <md-table-cell
                :md-label="$t('groups.access_level')"
                md-sort-by="access_level"
              >{{ item.access_level }}</md-table-cell>
              <md-table-cell
                :md-label="$t('groups.region')"
                md-sort-by="region_id"
              >{{ item.region_id }}</md-table-cell>
              <md-table-cell class :md-label="$t('tables.actions')">
                <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="handleEdit(item)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button class="md-just-icon md-danger md-simple" @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              {{
              $t('label.showing_from_to_of_entries', {
              from: to===0?0: from + 1,
              to: to,
              total
              })
              }}
            </p>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
</template>
<script>
import {Pagination} from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'

export default {
  name: 'groups-list-form',
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name_en', 'name_ru', 'region_id'],
      searchedData: [],
      currentSort: 'name_en',
      currentSortOrder: 'asc',
      fuseSearch: null,
      footerTable: []
    }
  },
  components: {
    Pagination
  },
  methods: {
    customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          const res = (!a[sortBy]) ? -1 : (!b[sortBy]) ? 1 :
            a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })
          return (this.currentSortOrder === 'desc') ? -res : res
        })
      },
    handleEdit(item) {
      const {id} = item
      this.$router.push(`group_upd/${id}`)
    },
    handleDelete(item) {
      Swal.fire({
        title: 'Вы уверены?',
        text: `Операцию невозможно отменить!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'md-button md-danger btn-fill',
        cancelButtonClass: 'md-button md-success btn-fill',
        confirmButtonText: 'Да, удалить!',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_GROUP', item.id).then(() => {
            Swal.fire({
              title: 'Удалено!',
              text: `Вы удалили ${item.name_en}`,
              type: 'success',
              confirmButtonClass: 'md-button md-success btn-fill',
              buttonsStyling: false
            })
          })
        }
      })
    },
    groupProfile() {
      this.$router.push(`group_add`)
    }
  },
  mounted() {
    this.$store.dispatch('LOAD_GROUP_LIST').then(() => {
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
      })
    })
  },
  computed: {
    groupList() {
        return this.$store.state.Groups.list
      },
      me(){ (state) => this.$store.state.Login.me},
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData //? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.tableData.length
      },
      tableData() {
        return this.groupList
      }
  },
  watch: {
    /*groupList() {
      let result = this.tableData
      if (this.searchQuery !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    },*/
    searchQuery(value) {
        if (this.groupList.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value !== '') ?this.fuseSearch.search(this.searchQuery) : this.tableData
      }
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>